<template>
  <div class="blank-payment-receipt">
    <IncludesForBlanks />
    <div class="blank-payment-receipt__content">
      <table class="blank-payment-receipt__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <!--              <img-->
              <!--                :src="companyLogo"-->
              <!--                width="130"-->
              <!--                height="40"-->
              <!--                :alt="companyName"-->
              <!--                class="blank-payment-receipt__logo"-->
              <!--              />-->
              <div
                :style="{
                  backgroundImage: `url('${companyLogo}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }"
                class="blank-payment-receipt__logo"
              ></div>
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-payment-receipt__name">Payment receipt</div>
              <div class="blank-payment-receipt__number">
                {{ inputs.number }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-payment-receipt__info">
        <table class="blank-payment-receipt__main-info">
          <tbody>
            <tr>
              <td class="col-8">
                <table class="blank-payment-receipt__info-table">
                  <tr>
                    <td>Payment Date</td>
                    <td>{{ inputs.payment_date | dateFormat }}</td>
                  </tr>
                  <tr>
                    <td>Reference Number</td>
                    <td>{{ inputs.reference_number }}</td>
                  </tr>
                  <tr>
                    <td>Payment Mode</td>
                    <td>
                      {{
                        paymentReceived.payment_method &&
                        paymentReceived.payment_method.name
                      }}
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <div class="blank-payment-receipt__amount-wrap">
                  <h4 class="blank-payment-receipt__amount-title">
                    Amount Received
                  </h4>
                  <p class="blank-payment-receipt__amount">
                    {{ inputs.amount_received | dollarFormat }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="blank-payment-receipt__bill-to">
          <h4 class="blank-payment-receipt__bill-to-title">Bill To</h4>

          <div>
            {{ customer.first_name }} {{ customer.last_name }}
            <div v-if="customer.company_name" class="g-w-bold">
              {{ customer.company_name }}
            </div>
          </div>

          <template v-for="address in customer.addresses">
            <div
              :key="address.id"
              v-html="formatAddress(address.value, 'two_rows')"
              style="margin-top: 8px"
            />
          </template>
        </div>

        <div class="blank-payment-receipt__invoices-wrap">
          <div
            v-if="unappliedAmount"
            class="blank-payment-receipt__invoices-over-payment"
          >
            <h4 class="blank-payment-receipt__invoices-over-payment-title">
              Unapplied amount
            </h4>
            <div class="blank-payment-receipt__invoices-over-payment-value">
              {{ unappliedAmount | dollarFormat }}
            </div>
          </div>

          <h3 class="blank-payment-receipt__invoices-title">Payment for</h3>

          <table
            class="blank-payment-receipt__invoices-table light-table d-table"
          >
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Invoice Amount</th>
                <th>Payment Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in paymentReceived.items" :key="invoice.id">
                <td>{{ invoice.invoice.attributes.inputs.number }}</td>
                <td>
                  {{
                    invoice.invoice.attributes.inputs.dates.invoice_date
                      | dateFormat
                  }}
                </td>
                <td>
                  {{ invoice.invoice.attributes.inputs.total | dollarFormat }}
                </td>
                <td>{{ invoice.inputs.amount | dollarFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-payment-receipt.less'
import formatAddress from '@/helpers/formatAddress'
// import getUnappliedAmount from '@/helpers/paymentsReceived/getUnappliedAmount'

export default {
  components: {
    IncludesForBlanks,
  },
  props: {
    paymentReceived: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wasMounted: false,
    }
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    inputs() {
      return this.paymentReceived.inputs || {}
    },
    customer() {
      return this.paymentReceived.customer
    },
    // appliedAmount() {
    //   const invoices = this.paymentReceived.items || []
    //
    //   return invoices.reduce((sum, invoice) => {
    //     let amount = invoice.inputs.amount
    //     return sum + (amount || 0)
    //   }, 0)
    // },
    unappliedAmount() {
      // return getUnappliedAmount(this.paymentReceived)
      return this.paymentReceived.unapplied_amount
    },
  },
  mounted() {
    this.wasMounted = true
  },
  methods: {
    getStyles() {
      return styles
    },
    formatAddress(...args) {
      return formatAddress(...args)
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-payment-receipt.less';
</style>
