export const TASK_STATUS_IN_PROGRESS = 'in_progress'
export const TASK_STATUS_COMPLETE = 'complete'
export const STATUS_OPEN = 'open'
export const STATUS_ACTIVE = 'active'
export const STATUS_CLOSED = 'closed'
export const STATUS_DELETED = 'deleted'
export const statusOptions = [
  {
    title: 'Open',
    value: STATUS_OPEN,
  },
  {
    title: 'Active',
    value: STATUS_ACTIVE,
  },
  {
    title: 'Closed',
    value: STATUS_CLOSED,
  },
  {
    title: 'Deleted',
    value: STATUS_DELETED,
  },
]

export const statusesList = statusOptions.map((item) => item.value)

export const taskStatusOptions = [
  {
    value: TASK_STATUS_IN_PROGRESS,
    label: 'In progress',
  },
  {
    value: TASK_STATUS_COMPLETE,
    label: 'Complete',
  },
]
