import Vue from 'vue'
import { axiosApi } from '@/axios'
import { get } from 'lodash'
import { ATTACHMENT_KEY_COMMODITY_FILES } from '@/constants/mail'

function getState() {
  return {
    pending: false,
    templates: [],
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    addToMail({ commit, dispatch }, payload) {
      commit('setProperty', ['pending', true])

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const emailPlaceholders = {
          CompanyName: get(payload, 'commodity.customer.company_name', null),
        }

        let templates = await axiosApi.get(
          '/settings/email_templates/commodity_files/'
        )
        templates = get(templates, 'data.data', [])

        if (templates.length) {
          templates.forEach((item) => {
            for (let key in emailPlaceholders) {
              if (emailPlaceholders[key]) {
                const regex = new RegExp('{{' + key + '}}', 'gi')
                item.attributes.subject = item.attributes.subject.replace(
                  regex,
                  emailPlaceholders[key]
                )
                item.attributes.message = item.attributes.message.replace(
                  regex,
                  emailPlaceholders[key]
                )
              }
            }
          })

          commit('setProperty', ['templates', templates])
        }

        const customerEmail = get(
          payload,
          'commodity.customer.emails[0].value',
          null
        )

        let name = ''
        if (payload.commodity.type && payload.commodity.type.vin_field) {
          name = `${payload.commodity.commodity_item?.inputs?.year ?? ''} ${
            payload.commodity.commodity_item?.inputs?.make_model ?? ''
          }`
        } else if (payload.commodity.type) {
          name = payload.commodity.commodity_item?.inputs?.descr ?? ''
        } else {
          // in an uncommon situation
          if (payload.commodity.commodity_item.inputs.descr) {
            name = payload.commodity.commodity_item?.inputs?.descr ?? ''
          } else {
            name = `${payload.commodity.commodity_item?.inputs?.year ?? ''} ${
              payload.commodity.commodity_item?.inputs?.make_model ?? ''
            }`
          }
        }

        const attachments = JSON.parse(
          JSON.stringify(payload.selectedFiles)
        ).map((item) => {
          if (name.trim()) {
            item.name = `${name.trim()} - ${
              item.file_original_client_name || ''
            }`
          } else {
            item.name = `${item.file_original_client_name || ''}`
          }

          return {
            id: item.id,
            name: item.name,
            attachment: item,
          }
        })

        await dispatch(
          'mail/addToMail',
          {
            sendTo: [customerEmail],
            templates,
            attachments: {
              [ATTACHMENT_KEY_COMMODITY_FILES]: attachments,
            },
          },
          { root: true }
        )

        resolve()
      }).finally(() => {
        commit('setProperty', ['pending', false])
      })
    },
  },
}
