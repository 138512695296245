import Vue from 'vue'
import { CancelToken } from 'axios'
import { axiosApi } from '@/axios'
// import router from '../../router'
import {
  QUERY_PAGE_NAME,
  QUERY_SEARCH_NAME,
  QUERY_PORT_OF_LOADING_NAME,
  QUERY_PORT_OF_DISCHARGE_NAME,
  QUERY_PLACE_OF_DELIVERY_NAME,
  QUERY_SEARCH_GLOBAL_NAME,
} from '@/constants/commodity'
import { STATUS_WAITING_LIST } from '@/constants/commoditiesStatuses'

// import * as statuses from '../../constants/commoditiesStatuses'

function getState() {
  return {
    pending: false,
    cancelRequest: null,
    commoditiesItems: [],
    tabsTotals: {},
    destinations: [],
    total: 0,
    limit: 10,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    reset(state) {
      state.cancelRequest && state.cancelRequest()
      Object.assign(state, getState())
    },
    setItemSelection(state, { index, value }) {
      if (!state.commoditiesItems[index]) return
      Vue.set(state.commoditiesItems[index], 'selected', value)
    },
    setAllSelection(state, value) {
      state.commoditiesItems.forEach((item) => {
        Vue.set(item, 'selected', value)
      })
    },
  },
  actions: {
    load({ state, commit }, { route }) {
      if (typeof state.cancelRequest === 'function') {
        state.cancelRequest()
        commit('setProperty', ['cancelRequest', null])
      }

      const header = {
        cancelToken: new CancelToken(function executor(c) {
          commit('setProperty', ['cancelRequest', c])
        }),
        params: {
          limit: state.limit,
          query: route.query[QUERY_SEARCH_NAME],
          query_global: route.query[QUERY_SEARCH_GLOBAL_NAME],
          page: route.query[QUERY_PAGE_NAME] || 1,
          status: route.params.status || STATUS_WAITING_LIST,
          port_of_loading: route.query[QUERY_PORT_OF_LOADING_NAME],
          port_of_discharge: route.query[QUERY_PORT_OF_DISCHARGE_NAME],
          place_of_delivery: route.query[QUERY_PLACE_OF_DELIVERY_NAME],
          destinations: route.query.destinations,
          sort: route.query.sort,
          order: route.query.order,
        },
      }

      commit('setProperty', ['pending', true])
      commit('setProperty', ['commoditiesItems', []])
      commit('setProperty', ['total', 0])

      return axiosApi
        .get(`/commodities`, header)
        .then(({ data }) => {
          const items = data.data.map((item) => {
            item = item.attributes
            item.selected = false

            if (item.customer && typeof item.customer === 'string') {
              item.customer = JSON.parse(item.customer)
            }

            return item
          })

          commit('setProperty', ['commoditiesItems', items])
          commit('setProperty', [
            'tabsTotals',
            data.additional_information.tabs_totals,
          ])
          commit('setProperty', [
            'destinations',
            data.additional_information.destinations_totals,
          ])
          commit('setProperty', ['total', data.meta.total])
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
    // eslint-disable-next-line no-shadow-restricted-names
    delete(undefined, { id, sendData }) {
      return axiosApi
        .delete(`/commodities/${id}`, {
          params: {
            ...sendData,
          },
        })
        .catch((e) => {
          throw e
        })
    },
    // eslint-disable-next-line no-shadow-restricted-names
    deleteCompletely(undefined, { id }) {
      return axiosApi.delete(`/commodities/${id}/delete-completely`)
      // .catch(Vue.prototype.$errorCatchDefault)
    },
    restore({ commit }, id) {
      commit('setProperty', ['pending', true])

      return axiosApi
        .post(`/commodities/${id}/restore`)
        .catch((e) => {
          throw e
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
  getters: {
    // selectedAmount(state) {
    //   const a = state.commoditiesItems.reduce((result, item) => {
    //     return item.selected ? result + 1 : result
    //   }, 0)
    //   console.log('selectedAmount', a)
    //   return a
    // },
    // isSomeSelected(state, getters) {
    //   return !!(state.commoditiesItems.length && getters.selectedAmount)
    // },
    // isAllSelected(state, getters) {
    //   return state.commoditiesItems.length === getters.selectedAmount
    // },
  },
}
