<template>
  <div class="blank-booking">
    <IncludesForBlanks />
    <div class="blank-booking__content">
      <table class="blank-booking__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <!--              <img-->
              <!--                :src="companyLogo"-->
              <!--                width="130"-->
              <!--                height="40"-->
              <!--                :alt="companyName"-->
              <!--                class="blank-booking__logo"-->
              <!--              />-->
              <div
                :style="{
                  backgroundImage: `url('${companyLogo}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }"
                class="blank-booking__logo"
              ></div>
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-booking__name">Booking</div>
              <div class="blank-booking__number">
                {{ inputs.booking_number }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-booking__info">
        <BookingBlankTable
          :inputs="inputs"
          :get-info-for-docs="getInfoForDocs"
        />

        <pre
          v-if="inputs.remark"
          v-html="inputs.remark"
          class="blank-booking__remark text"
        ></pre>
      </div>

      <div v-if="truckingList.length" class="blank-booking__trucking-info">
        <div class="blank-booking__section-title">Trucking information</div>
        <div
          v-for="(trucking, truckingIndex) in truckingList"
          :key="truckingIndex"
          class=""
        >
          <div class="blank-booking__section-sub-title">
            <span>Trucking {{ truckingIndex + 1 }}</span>
          </div>

          <div
            class="blank-booking__trucking-table-wrap page-break-inside-avoid"
          >
            <table class="blank-booking__trucking-table">
              <thead>
                <tr>
                  <th>
                    <div class="blank-booking__cell-title">
                      Trucking company
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      v-html="getInfoForDocs(trucking.inputs.trucking_company)"
                      class="text"
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="blank-booking__trucking-table-wrap page-break-inside-avoid"
          >
            <table class="blank-booking__trucking-table">
              <thead>
                <tr>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.locations[0] }}
                    </div>
                  </th>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.locations[1] }}
                    </div>
                  </th>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.locations[2] }}
                    </div>
                  </th>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.locations[3] }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      $get(
                        trucking.inputs.empty_container_pick_up_location,
                        'address'
                      ) | addressFormat
                    }}
                  </td>
                  <td>
                    {{
                      $get(
                        trucking.inputs.empty_container_drop_location,
                        'address'
                      ) | addressFormat
                    }}
                  </td>
                  <td>
                    {{
                      $get(
                        trucking.inputs.loaded_container_pick_up_location,
                        'address'
                      ) | addressFormat
                    }}
                  </td>
                  <td>
                    {{
                      $get(
                        trucking.inputs.loaded_container_drop_location,
                        'address'
                      ) | addressFormat
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="blank-booking__trucking-table-wrap page-break-inside-avoid"
          >
            <table class="blank-booking__trucking-table">
              <thead>
                <tr>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.dates[0] }}
                    </div>
                  </th>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.dates[1] }}
                    </div>
                  </th>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.dates[2] }}
                    </div>
                  </th>
                  <th>
                    <div class="blank-booking__cell-title">
                      {{ ContainerTitle.dates[3] }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      trucking.inputs.dates.empty_container_pick_up_date
                        | dateFormat
                    }}
                  </td>
                  <td>
                    {{
                      trucking.inputs.dates.empty_container_drop_date
                        | dateFormat
                    }}
                  </td>
                  <td>
                    {{
                      trucking.inputs.dates.loaded_container_pick_up_date
                        | dateFormat
                    }}
                  </td>
                  <td>
                    {{
                      trucking.inputs.dates.loaded_container_drop_date
                        | dateFormat
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <pre
            v-if="trucking.inputs.instructions"
            v-html="trucking.inputs.instructions"
            style="margin-bottom: 25px"
            class="blank-booking__remark text"
          ></pre>

          <div class="blank-booking__containers-table-wrap">
            <table class="blank-booking__containers-table light-table">
              <thead>
                <tr>
                  <th>Container Number</th>
                  <th>Seal Number</th>
                  <th>Type/Size</th>
                  <th>SOC</th>
                  <th>Containerized</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(container, containerIndex) in trucking.containers"
                  :key="containerIndex"
                >
                  <td>{{ container.container_number }}</td>
                  <td>{{ container.seal_number }}</td>
                  <td>
                    {{
                      container.container_type && container.container_type.name
                    }}
                  </td>
                  <td>{{ container.soc ? 'Yes' : 'No' }}</td>
                  <td>{{ container.containerized ? 'Yes' : 'No' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-booking.less'
import BookingBlankTable from '@/components/Booking/BookingBlankTable'

// import PersonDocumentShow from '../PersonDocumentShow'
// import PersonDocumentShow from '../PersonDocumentShow'

export default {
  components: {
    BookingBlankTable,
    IncludesForBlanks,
    // PersonDocumentShow
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wasMounted: false,
      ContainerTitle: {
        locations: [
          'Empty container pick-up location',
          'Empty container drop Location',
          'Loaded container pick up Location',
          'Loaded container drop location',
        ],
        dates: [
          'Empty cont. pick-up date',
          'Empty cont. drop date',
          'Loaded cont. pick-up date',
          'Loaded cont. drop date',
        ],
      },
    }
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    inputs() {
      return this.booking.inputs || {}
    },
    truckingList() {
      let truckingList = this.booking.trucking_information || []

      // for (let trucking of truckingList) {
      //
      //   trucking.containers = trucking.containers.map(jsonStr => JSON.parse(jsonStr))
      // }
      return truckingList
    },
  },
  mounted() {
    this.wasMounted = true
  },
  methods: {
    getInfoForDocs(item) {
      return (
        (item &&
          item.information_for_documents &&
          item.information_for_documents.value) ||
        null
      )
    },
    getStyles() {
      return styles
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-booking.less';
</style>
