<template>
  <div v-if="invoice" class="blank-invoice">
    <IncludesForBlanks />

    <div class="blank-invoice__content">
      <table class="blank-invoice__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <!--              <img-->
              <!--                :src="companyLogo"-->
              <!--                width="130"-->
              <!--                height="40"-->
              <!--                :alt="companyName"-->
              <!--                class="blank-invoice__logo"-->
              <!--              />-->
              <div
                :style="{
                  backgroundImage: `url('${companyLogo}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }"
                class="blank-invoice__logo"
              ></div>
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-invoice__name">Invoice</div>
              <div class="blank-invoice__number">
                # {{ $get(invoice.inputs, 'number') }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-invoice__main">
        <div class="blank-invoice__info">
          <table class="blank-invoice__info">
            <tbody>
              <tr>
                <td style="padding: 0">
                  <div class="blank-invoice__info-list-title">Bill To</div>
                  <div class="blank-invoice__info-list">
                    <div
                      class="text"
                      v-html="
                        $get(
                          invoice.inputs,
                          'customer.attributes.information_for_documents.value'
                        )
                      "
                    ></div>
                  </div>
                </td>
                <td style="width: 200px; padding: 0">
                  <div
                    style="
                      font-weight: bold;
                      font-size: 14px;
                      text-align: right;
                      margin-bottom: 2rem;
                    "
                  >
                    Balance Due: {{ invoice.inputs.balance | dollarFormat }}
                  </div>
                  <table class="blank-invoice__info-table">
                    <tr>
                      <td>Date Created:</td>
                      <td>
                        {{
                          $get(invoice.inputs.dates, 'invoice_date')
                            | dateFormat
                        }}
                      </td>
                    </tr>
                    <tr v-if="invoice.inputs.term">
                      <td>Terms:</td>
                      <td>{{ $get(invoice.inputs.term, 'name') }}</td>
                    </tr>
                    <tr>
                      <td>Due Date:</td>
                      <td>
                        {{
                          $get(invoice.inputs.dates, 'due_date') | dateFormat
                        }}
                      </td>
                    </tr>
                    <template v-if="$get(invoice, 'inputs.type.id')">
                      <tr v-if="$get(invoice, 'inputs.related_to')">
                        <td
                          v-if="
                            $get(invoice, 'inputs.type.id') ===
                            RELATED_TO_SHIPPING_FILE_ID
                          "
                        >
                          Shipping file #:
                        </td>
                        <td
                          v-else-if="
                            $get(invoice, 'inputs.type.id') ===
                            RELATED_TO_PROJECT_ID
                          "
                        >
                          Project #:
                        </td>
                        <td>
                          <template
                            v-if="
                              $get(invoice, 'inputs.type.id') ===
                              RELATED_TO_SHIPPING_FILE_ID
                            "
                          >
                            <router-link
                              v-if="$get(invoice, 'inputs.related_to.id')"
                              :to="{
                                name: 'shipping-file',
                                params: {
                                  fileId: $get(invoice, 'inputs.related_to.id'),
                                },
                              }"
                              custom
                              v-slot="{ href }"
                            >
                              <a
                                :href="href"
                                @click.prevent="
                                  $vModal.open('file-show', {
                                    fileNumber:
                                      invoice.inputs.related_to.number,
                                    shippingFileId:
                                      invoice.inputs.related_to.id,
                                    callback: () => {
                                      $emit('refresh')
                                    },
                                  })
                                "
                                >{{
                                  $get(invoice, 'inputs.related_to.number')
                                }}</a
                              >
                            </router-link>
                            <span v-else>-</span>
                          </template>
                          <template
                            v-else-if="
                              $get(invoice, 'inputs.type.id') ===
                              RELATED_TO_PROJECT_ID
                            "
                          >
                            <router-link
                              v-if="$get(invoice, 'inputs.related_to.id')"
                              :to="{
                                name: 'project',
                                params: {
                                  projectId: $get(
                                    invoice,
                                    'inputs.related_to.id'
                                  ),
                                  status: invoice?.inputs?.related_to?.status,
                                },
                              }"
                              custom
                              v-slot="{ href }"
                            >
                              <a
                                :href="href"
                                @click.prevent="
                                  $vModal.open('project-show', {
                                    number:
                                      invoice.inputs.related_to.project_number,
                                    id: invoice.inputs.related_to.id,
                                    callback: () => {
                                      $emit('refresh')
                                    },
                                  })
                                "
                                >{{
                                  $get(
                                    invoice,
                                    'inputs.related_to.project_number'
                                  )
                                }}</a
                              >
                            </router-link>
                            <span v-else>-</span>
                          </template>
                        </td>
                      </tr>
                      <template
                        v-if="
                          $get(invoice, 'inputs.type.id') ===
                          RELATED_TO_SHIPPING_FILE_ID
                        "
                      >
                        <tr
                          v-if="
                            $get(
                              invoice,
                              'inputs.related_to.booking.inputs.booking_number'
                            )
                          "
                        >
                          <td>Booking #:</td>
                          <td>
                            <router-link
                              :to="{
                                name: 'booking',
                                params: {
                                  bookingId: $get(
                                    invoice,
                                    'inputs.related_to.booking.id'
                                  ),
                                },
                              }"
                              custom
                              v-slot="{ href }"
                            >
                              <a
                                :href="href"
                                @click.prevent="
                                  $vModal.open('booking', {
                                    id: invoice.inputs.related_to.booking.id,
                                    callback: (booking) => {
                                      $emit('refresh', booking)
                                    },
                                  })
                                "
                                >{{
                                  invoice.inputs.related_to.booking.inputs
                                    .booking_number
                                }}</a
                              >
                            </router-link>
                          </td>
                        </tr>
                        <tr v-if="containerNumbers.length">
                          <td>Container #:</td>
                          <td>
                            {{ containerNumbers.join(', ') }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="blank-invoice__items-wrap">
          <div class="blank-invoice__items-title">Invoice items</div>
          <table class="blank-invoice__items-table">
            <thead>
              <tr>
                <th>Item</th>
                <th v-if="isTaxForm">Quantity / Rate</th>
                <th v-if="isTaxForm">Tax</th>
                <th>Description</th>
                <th>Amount, $</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in $get(invoice.inputs, 'items')">
                <tr
                  :key="index"
                  :class="{
                    '_has-contract': !!item?.applied_contract?.contract,
                  }"
                >
                  <td>{{ item.name }}</td>
                  <td v-if="isTaxForm">
                    {{ item.quantity }} <span class="g-c-gray">/</span>
                    {{ item.rate | dollarFormat }}
                  </td>
                  <td v-if="isTaxForm">
                    <div v-if="item.tax">
                      {{ item.tax.name }}
                      (<span v-if="item.tax.value_type === '$'">{{
                        item.tax.value | dollarFormat
                      }}</span>
                      <span v-else-if="item.tax.value_type === '%'"
                        >{{ item.tax.value }}%</span
                      >
                      <span v-else>{{ item.tax.value }}</span
                      >)
                    </div>
                  </td>
                  <td>
                    <div v-html="item.description" class="text"></div>
                  </td>
                  <td>{{ item.amount | dollarFormat }}</td>
                </tr>
                <tr
                  v-if="item?.applied_contract?.contract"
                  :key="`${index}_contract`"
                  class="contract-item"
                >
                  <td colspan="3">
                    <b class="g-c-gray-dark">By contract:&nbsp;</b>
                    <span class="d-inline-block">
                      <a
                        href="#"
                        @click.prevent="
                          $vModal.open('project-contract-show', {
                            id: item?.applied_contract?.contract?.id,
                            callback: () => {
                              $emit('refresh')
                            },
                          })
                        "
                        >#{{ item.applied_contract.contract?.number }}</a
                      >
                    </span>
                    |
                    <span class="d-inline-block"
                      >Date:&nbsp;{{
                        item.applied_contract.contract?.date | dateFormat
                      }}</span
                    >
                    |
                    <span class="d-inline-block"
                      >Amount:&nbsp;{{
                        item.applied_contract.contract?.amount | dollarFormat
                      }}</span
                    >
                    |
                    <span class="d-inline-block"
                      >Balance:&nbsp;{{
                        item.applied_contract.contract?.balance | dollarFormat
                      }}</span
                    >
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div style="page-break-before: auto; height: 2rem"></div>
          <table class="blank-invoice__items-table _subtotals">
            <tbody>
              <template
                v-if="
                  invoice.inputs.subtotal_details.length ||
                  invoice.inputs.adjustments
                "
              >
                <tr style="font-weight: bold">
                  <td>Subtotal:</td>
                  <td></td>
                  <td>{{ invoice.inputs.subtotal | dollarFormat }}</td>
                </tr>
                <tr v-if="invoice.inputs.adjustments">
                  <td>
                    {{ invoice.inputs.adjustments_name || 'Adjustments' }}
                  </td>
                  <td></td>
                  <td>
                    <span>{{ invoice.inputs.adjustments | dollarFormat }}</span>
                  </td>
                </tr>
                <tr
                  v-for="(subtotal, index) in invoice.inputs.subtotal_details"
                  :key="index"
                >
                  <td>{{ subtotal.name }}</td>
                  <td>{{ subtotal.type }}</td>
                  <td>
                    <span v-if="subtotal.value_type === '$'">{{
                      subtotal.value | dollarFormat
                    }}</span>
                    <span v-else-if="subtotal.value_type === '%'"
                      >{{ subtotal.value }}%</span
                    >
                    <span v-else>{{ subtotal.value }}</span>
                  </td>
                </tr>
              </template>
              <tr>
                <td style="font-size: 14px; font-weight: bold">Total:</td>
                <td style=""></td>
                <td style="font-size: 14px; font-weight: bold">
                  {{ invoice.inputs.total | dollarFormat }}
                </td>
              </tr>
              <tr v-if="invoice.inputs.payment">
                <td style="font-size: 14px">Payment Made:</td>
                <td style=""></td>
                <td style="font-size: 14px; color: #8f1b2c">
                  -{{ invoice.inputs.payment | dollarFormat }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    background-color: #f2f2f2;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  Balance Due:
                </td>
                <td style="background-color: #f2f2f2"></td>
                <td
                  style="
                    background-color: #f2f2f2;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  {{ invoice.inputs.balance | dollarFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="invoice?.notes?.length || invoice?.inputs?.terms_and_conditions"
          style="margin-top: 3rem"
          class="blank-invoice__info"
        >
          <template v-if="invoice?.notes?.length">
            <div class="blank-invoice__info-list-title">Notes</div>
            <p v-for="(item, index) in invoice.notes" :key="index">
              {{ item.note }}
            </p>
          </template>
          <template v-if="invoice?.inputs?.terms_and_conditions">
            <div
              class="blank-invoice__info-list-title"
              style="margin-top: 3rem"
            >
              Terms & Conditions
            </div>
            <p>
              {{ invoice.inputs.terms_and_conditions }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  RELATED_TO_PROJECT_ID,
  RELATED_TO_SHIPPING_FILE_ID,
} from '@/constants/relatedTo'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-invoice.less'
import { FORMAT_TAX } from '@/constants/invoices'
import { STATUS_PENDING_SHIPPING } from '@/constants/shippingFiles'
import { mapState } from 'vuex'

export default {
  name: 'BInvoice',
  components: {
    IncludesForBlanks,
  },
  data() {
    return {
      RELATED_TO_SHIPPING_FILE_ID,
      RELATED_TO_PROJECT_ID,
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    //
    isTaxForm() {
      return this.invoice.inputs.form_variant === FORMAT_TAX
    },
    paymentMade() {
      return this.invoice.inputs.total - this.invoice.inputs.balance
    },
    containerNumbers() {
      return this.$get(
        this.invoice,
        'inputs.related_to.booking.trucking_information',
        []
      ).reduce((acc, item) => {
        const r = this.$get(item, 'containers', []).reduce((acc2, item2) => {
          if (item2.container_number) {
            acc2.push(item2.container_number)
          }
          return acc2
        }, [])

        return acc.concat(r)
      }, [])
    },
  },
  methods: {
    getStyles() {
      return styles
    },
    getShippingFileStatus(file) {
      const status = this.$get(file, 'shipping_file.status')
      return status ? status.replace(' ', '_') : STATUS_PENDING_SHIPPING
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css-blanks/blank-invoice.less';
</style>
