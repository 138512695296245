import { render, staticRenderFns } from "./BShippingFile.vue?vue&type=template&id=081da058&scoped=true"
import script from "./BShippingFile.vue?vue&type=script&lang=js"
export * from "./BShippingFile.vue?vue&type=script&lang=js"
import style0 from "./BShippingFile.vue?vue&type=style&index=0&id=081da058&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081da058",
  null
  
)

export default component.exports