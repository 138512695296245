var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"css":false},on:{"before-enter":() => _vm.handleBeforeEnter(),"enter":(el, done) => _vm.handleEnter(el, done),"after-enter":(el) => _vm.handleAfterEnter(el),"before-leave":(el) => _vm.handleBeforeLeave(el),"leave":(el, done) => _vm.handleLeave(el, done),"after-leave":(el) => _vm.handleAfterLeave(el)}},[_c(_vm.tag,{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],tag:"component",class:[
      _vm.collapsing && !_vm.isInit ? 'collapsing' : 'collapse',
      {
        'collapse-horizontal': _vm.horizontal,
        show: _vm.show,
      },
    ],style:({ '--collapseDuration': _vm.duration + 'ms' })},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }