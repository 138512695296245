<template>
  <div class="blank-shipping-file">
    <IncludesForBlanks />
    <div class="blank-shipping-file__content">
      <table class="blank-shipping-file__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <!--              <img-->
              <!--                :src="companyLogo"-->
              <!--                width="130"-->
              <!--                height="40"-->
              <!--                :alt="companyName"-->
              <!--                class="blank-shipping-file__logo"-->
              <!--              />-->
              <div
                :style="{
                  backgroundImage: `url('${companyLogo}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }"
                class="blank-shipping-file__logo"
              ></div>
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-shipping-file__name">Shipping file</div>
              <div class="blank-shipping-file__number">{{ inputs.number }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-shipping-file__info"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-booking.less'

export default {
  components: {
    IncludesForBlanks,
  },
  props: {
    shippingFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
    inputs() {
      return this.shippingFile.inputs || {}
    },
    truckingList() {
      return this.shippingFile.trucking_information || []
    },
  },
  methods: {
    getInfoForDocs(item) {
      return (
        (item &&
          item.information_for_documents &&
          item.information_for_documents.value) ||
        null
      )
    },
    getStyles() {
      return styles
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-shipping-file.less';
</style>
