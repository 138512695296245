import Vue from 'vue'
import { axiosApi } from '@/axios'
import createBookingBlanks from '@/actions/createBookingBlanks'
import openInNewTab from '@/helpers/openInNewTab'

function getState() {
  return {
    pending: false,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
  },
  actions: {
    createPdf(_, bookings) {
      return createBookingBlanks(bookings).then((result) => {
        const newFormData = new FormData()

        result.forEach(({ formData }, index) => {
          for (const [key, value] of formData.entries()) {
            newFormData.append(`item[${index}][${key}]`, value)
          }
        })

        return axiosApi.post('/pdf', newFormData)
      })
    },
    openPdf({ commit, dispatch }, { bookings }) {
      commit('setProperty', ['pending', true])

      return dispatch('createPdf', bookings)
        .then(({ data }) => {
          openInNewTab(data.single)
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
  },
}
