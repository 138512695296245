<template>
  <div>
    <v-modal name="project-edit">
      <template v-slot:title="{ id }">
        {{ `${id ? 'Edit' : 'New'} project` }}
      </template>
      <template v-slot:default="props">
        <ProjectEdit v-bind="props" :modalsToClose="['project-edit']" />
      </template>
    </v-modal>
    <v-modal name="project-show" title="Project" class="_x-wide _full-content">
      <template v-slot:default="props">
        <ProjectShow v-bind="props" is-modal />
      </template>
    </v-modal>

    <v-modal name="project-subcontract-edit">
      <template v-slot:title="{ id }">
        {{ `${id ? 'Edit' : 'New'} subcontract` }}
      </template>
      <template v-slot:default="props">
        <ProjectSubcontractEdit
          v-bind="props"
          :modalsToClose="['project-subcontract-edit']"
        />
      </template>
    </v-modal>

    <v-modal name="project-contract-edit" class="_wide">
      <template v-slot:title="{ id }">
        {{ `${id ? 'Edit' : 'New'} contract` }}
      </template>
      <template v-slot:default="props">
        <ProjectContractEdit
          v-bind="props"
          :modalsToClose="['project-contract-edit']"
        />
      </template>
    </v-modal>

    <v-modal
      name="project-subcontracts-attach"
      title="Attach subcontracts"
      class="_x-wide _full-content"
    >
      <template v-slot:default="props">
        <ProjectSubcontractsAttach v-bind="props" />
      </template>
    </v-modal>

    <v-modal
      name="project-subcontract-show"
      title="Project subcontract"
      class="_wide _full-content"
    >
      <template v-slot:default="props">
        <ProjectSubcontractShow v-bind="props" is-modal />
      </template>
    </v-modal>

    <v-modal
      name="project-contract-show"
      title="Project contract"
      class="_wide _full-content"
    >
      <template v-slot:default="props">
        <ProjectContractShow v-bind="props" is-modal />
      </template>
    </v-modal>

    <v-modal
      name="project-contracts-attach"
      title="Attach contracts"
      class="_x-wide _full-content"
    >
      <template v-slot:default="props">
        <ProjectContractsAttach v-bind="props" />
      </template>
    </v-modal>
  </div>
</template>

<script>
const ProjectContractsAttach = () =>
  import(
    /* webpackChunkName: "ProjectContractsAttach" */ '@/components/Projects/ProjectContractsAttach.vue'
  )
const ProjectSubcontractsAttach = () =>
  import(
    /* webpackChunkName: "ProjectSubcontractsAttach" */ '@/components/Projects/ProjectSubcontractsAttach.vue'
  )

const ProjectEdit = () =>
  import(
    /* webpackChunkName: "ProjectEdit" */ '@/components/Projects/ProjectEdit'
  )
const ProjectSubcontractEdit = () =>
  import(
    /* webpackChunkName: "ProjectEdit" */ '@/components/Projects/ProjectSubcontractEdit.vue'
  )
const ProjectContractEdit = () =>
  import(
    /* webpackChunkName: "ProjectEdit" */ '@/components/Projects/ProjectContractEdit.vue'
  )
const ProjectShow = () =>
  import(
    /* webpackChunkName: "ProjectShow" */ '@/components/Projects/ProjectShow.vue'
  )
const ProjectSubcontractShow = () =>
  import(
    /* webpackChunkName: "ProjectSubcontractShow" */ '@/components/Projects/ProjectSubcontractShow.vue'
  )
const ProjectContractShow = () =>
  import(
    /* webpackChunkName: "ProjectContractShow" */ '@/components/Projects/ProjectContractShow.vue'
  )

export default {
  name: 'ProjectModals',
  components: {
    ProjectSubcontractsAttach,
    ProjectContractEdit,
    ProjectSubcontractShow,
    ProjectShow,
    ProjectEdit,
    ProjectSubcontractEdit,
    ProjectContractShow,
    ProjectContractsAttach,
  },
}
</script>
