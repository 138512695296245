<template>
  <div v-if="person" class="blank-customer">
    <IncludesForBlanks />

    <div class="blank-customer__content">
      <table class="blank-customer__head">
        <tbody>
          <tr>
            <td style="width: 25%">
              <!--              <img-->
              <!--                :src="companyLogo"-->
              <!--                width="130"-->
              <!--                height="40"-->
              <!--                :alt="companyName"-->
              <!--                class="blank-customer__logo"-->
              <!--              />-->
              <div
                :style="{
                  backgroundImage: `url('${companyLogo}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }"
                class="blank-customer__logo"
              ></div>
            </td>
            <td class="text" v-html="companyInfo"></td>
            <td>
              <div class="blank-customer__name">CUSTOMER</div>
              <div class="blank-customer__number">{{ person.id }}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="blank-customer__info">
        <table class="blank-customer__info-table">
          <tbody>
            <tr>
              <td>
                <img
                  :src="person.photo_url"
                  class="blank-customer__info-table-image"
                  alt=""
                />
              </td>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Name</strong
                >
                <div>
                  {{ person.salutation }} {{ person.first_name }}
                  {{ person.last_name }}
                </div>
              </td>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Company</strong
                >
                <div>{{ person.company_name }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Phone</strong
                >
                <div v-for="(phone, index) in person.phones" :key="index">
                  <span>{{ phone.type }}: {{ phone.value }}</span>
                  <span v-if="phone.viber" title="viber">
                    <BaseSvg name="ico-viber" />
                  </span>
                  <span v-if="phone.whatsapp" title="whatsapp">
                    <BaseSvg name="ico-whatsapp" />
                  </span>
                </div>
              </td>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Email</strong
                >
                <div v-for="(email, index) in person.emails" :key="index">
                  <span>{{ email.type }}: {{ email.value }}</span>
                </div>
              </td>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Address</strong
                >
                <div v-for="(address, index) in person.addresses" :key="index">
                  <table class="blank-customer__info-sub-table">
                    <tr>
                      <td colspan="1" style="padding: 0">
                        <span>{{ address.type }}:</span>
                      </td>
                      <td colspan="3" style="padding-right: 0">
                        <span
                          v-html="formatAddress(address.value, true)"
                        ></span>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Birthday</strong
                >
                <div v-for="(birthday, index) in person.birthday" :key="index">
                  {{ birthday.value | dateFormat }}
                </div>
              </td>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Prefer language</strong
                >
                <div>{{ person.prefer_language }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Document</strong
                >
                <div
                  v-for="(document, index) in person.documents"
                  :key="index"
                  class="blank-customer__one-document"
                >
                  <div>{{ document.type }}: {{ document.value }}</div>
                  <div>Ex. date: {{ document.expired_date | dateFormat }}</div>
                </div>
              </td>
              <td>
                <strong class="blank-customer__info-table-column-title"
                  >Remark</strong
                >
                <div v-for="(remark, index) in person.remark" :key="index">
                  {{ remark.value }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IncludesForBlanks from './common/IncludesForBlanks'
import styles from '!!raw-loader!less-loader!@/assets/css-blanks/blank-customer.less'
import formatAddress from '../../helpers/formatAddress'

export default {
  name: 'BCustomer',
  components: {
    IncludesForBlanks,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['company_profile']),
    companyLogo() {
      return (
        this.$get(this.company_profile, 'logo_crop.attributes.thumbnails_md') ||
        this.$get(this.company_profile, 'logo_crop.attributes.path') ||
        this.$get(this.company_profile, 'logo_full.attributes.path')
      )
    },
    companyInfo() {
      return (
        this.$get(this.company_profile, 'information_for_documents.value') ||
        this.$get(this.company_profile, 'company_name')
      )
    },
    companyName() {
      return this.$get(this.company_profile, 'company_name')
    },
  },
  methods: {
    getStyles() {
      return styles
    },
    formatAddress(...d) {
      return formatAddress(...d)
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/css-blanks/blank-customer.less';
</style>
