import Vue from 'vue'
import { axiosApi } from '@/axios'

function getInitState() {
  return {
    loadingContainers: null,
    pendingLoad: false,
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
    removeCommodityById(state, id) {
      id = parseFloat(id)

      let commodityIndex = null

      const containerIndex = state.loadingContainers.findIndex((container) => {
        const index = container.loaded_commodities.findIndex((commodity) => {
          return commodity.id === id
        })

        if (index >= 0) {
          commodityIndex = index
          return true
        }
      })

      Vue.delete(
        state.loadingContainers[containerIndex].loaded_commodities,
        commodityIndex
      )
    },
  },
  actions: {
    load({ commit }, id) {
      commit('setProperty', ['pendingLoad', true])

      return axiosApi
        .get(`/shipping_files/${id}/get_loaded_commodities`)
        .then(({ data }) => {
          const loadingContainers = data.data.map((item) => item.attributes)
          commit('setProperty', ['loadingContainers', loadingContainers])
        })
        .finally(() => {
          commit('setProperty', ['pendingLoad', false])
        })
    },
    removeCommodity({ commit }, { commodityId }) {
      commit('setProperty', ['pendingLoad', true])

      return axiosApi
        .put(`/shipping_files/remove_commodity_from_loading`, {
          commodity: commodityId,
        })
        .then(() => {
          commit('removeCommodityById', commodityId)
        })
        .finally(() => {
          commit('setProperty', ['pendingLoad', false])
        })
    },
    uploadFiles({ state, commit }, { commodityId, files }) {
      // console.log(state.props)
      if (!state.personOne.id) return
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }

      commit('pendingFiles', true)

      axiosApi
        .post(`commodities/${commodityId}/add-loading-file/`, formData)
        .then(({ data }) => {
          commit(
            'addFiles',
            data.map((item) => item.attributes)
          )
        })
        .finally(() => {
          commit('pendingFiles', false)
        })
    },
    changeName({ state, commit }, { index, name, commodityId }) {
      const file = state.personOne.files[index]

      const sendData = {
        name,
      }

      commit('pendingFiles', true)
      axiosApi
        .put(
          `commodities/${commodityId}/files/${file.id}/change_name`,
          sendData
        )
        .then(({ data }) => {
          commit('changeFile', { index, file: data.item.attributes })
        })
        .finally(() => {
          commit('pendingFiles', false)
        })
    },
    deleteFile({ state, commit }, { index, commodityId }) {
      const file = state.personOne.files[index]
      commit('pendingFiles', true)
      axiosApi
        .delete(`commodities/${commodityId}/files/${file.id}`)
        .then(() => {
          commit('deleteFile', index)
        })
        .finally(() => {
          commit('pendingFiles', false)
        })
    },
  },
}
