<template>
  <transition
    :css="false"
    @before-enter="() => handleBeforeEnter()"
    @enter="(el, done) => handleEnter(el, done)"
    @after-enter="(el) => handleAfterEnter(el)"
    @before-leave="(el) => handleBeforeLeave(el)"
    @leave="(el, done) => handleLeave(el, done)"
    @after-leave="(el) => handleAfterLeave(el)"
  >
    <component
      :is="tag"
      :style="{ '--collapseDuration': duration + 'ms' }"
      :class="[
        collapsing && !isInit ? 'collapsing' : 'collapse',
        {
          'collapse-horizontal': horizontal,
          show: show,
        },
      ]"
      v-show="active"
    >
      <slot></slot>
    </component>
  </transition>
</template>

<script>
import { executeAfterTransition } from './transition'

export default {
  name: 'VSlideToggle',
  props: {
    active: Boolean,
    horizontal: Boolean,
    duration: {
      type: Number,
      default: () => 500,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      show: this.active,
      collapsing: false,
      isInit: true,
    }
  },
  methods: {
    handleBeforeEnter() {
      this.collapsing = true
    },
    handleEnter(el, done) {
      this.$emit('show')
      setTimeout(() => {
        executeAfterTransition(() => done?.(), el)
        if (this.horizontal) {
          el.style.width = `${el.scrollWidth}px`
          return
        }
        el.style.height = `${el.scrollHeight}px`
      }, 1)
    },
    handleAfterEnter(el) {
      this.show = true
      this.collapsing = false
      this.horizontal
        ? el.style.removeProperty('width')
        : el.style.removeProperty('height')
    },
    handleBeforeLeave(el) {
      this.collapsing = true
      this.show = false
      if (this.horizontal) {
        el.style.width = `${el.scrollWidth}px`
        return
      }
      el.style.height = `${el.scrollHeight}px`
    },
    handleLeave(el, done) {
      this.$emit('hide')
      setTimeout(() => {
        executeAfterTransition(() => done?.(), el)
        if (this.horizontal) {
          el.style.width = '0px'
          return
        }
        el.style.height = '0px'
      }, 1)
    },
    handleAfterLeave(el) {
      this.collapsing = false
      this.horizontal
        ? el.style.removeProperty('width')
        : el.style.removeProperty('height')
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.isInit = false
      }, 300)
    })
  },
}
</script>

<style lang="less" scoped>
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height var(--collapseDuration, 0.35s) ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width var(--collapseDuration, 0.35s) ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
</style>
