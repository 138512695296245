import abilityActions from '@/services/ability/abilityActions'
import abilitySubjects from '@/services/ability/abilitySubjects'
import { statusesList, STATUS_OPEN } from '@/constants/projects'

export default [
  {
    path: 'projects',
    name: 'projects-group',
    redirect: {
      name: 'projects',
      params: {
        status: STATUS_OPEN,
      },
    },
  },
  {
    path: `projects/list/:status(${statusesList.join('|')})`,
    name: 'projects-by-status',
    component: () =>
      import(/* webpackChunkName: "Projects" */ '@/views/Main/Projects'),
    meta: {
      permission: [abilityActions.read, abilitySubjects.PROJECTS],
      title: 'Projects',
    },
    children: [
      {
        path: ':projectId',
        name: 'project',
        component: () =>
          import(
            /* webpackChunkName: "ProjectPage" */ '@/views/Main/Projects/ProjectPage'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.PROJECTS],
        },
      },
    ],
  },
  {
    path: `projects/list/:status(${statusesList.join('|')})?`,
    name: 'projects',
    redirect: (to) => {
      return {
        name: 'projects-by-status',
        params: {
          status: to?.params?.status ?? STATUS_OPEN,
        },
      }
    },
  },
  {
    path: `projects/contracts`,
    name: 'project-contracts',
    component: () =>
      import(
        /* webpackChunkName: "ProjectContracts" */ '@/views/Main/ProjectContracts'
      ),
    meta: {
      permission: [abilityActions.read, abilitySubjects.PROJECTS],
      title: 'Contracts',
    },
    children: [
      {
        path: ':projectContractId',
        name: 'project-contract',
        component: () =>
          import(
            /* webpackChunkName: "ProjectContractPage" */ '@/views/Main/ProjectContracts/ProjectContractPage'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.PROJECTS],
        },
      },
    ],
  },
  {
    path: `projects/subcontracts`,
    name: 'project-subcontracts',
    component: () =>
      import(
        /* webpackChunkName: "ProjectSubcontracts" */ '@/views/Main/ProjectSubcontracts'
      ),
    meta: {
      permission: [abilityActions.read, abilitySubjects.PROJECTS],
      title: 'Subcontracts',
    },
    children: [
      {
        path: ':projectSubcontractId',
        name: 'project-subcontract',
        component: () =>
          import(
            /* webpackChunkName: "ProjectSubcontractPage" */ '@/views/Main/ProjectSubcontracts/ProjectSubcontractPage.vue'
          ),
        meta: {
          permission: [abilityActions.read, abilitySubjects.PROJECTS],
        },
      },
    ],
  },
]
