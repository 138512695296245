// import {TYPE_CAR, TYPE_OTHER} from '@/constants/commodity'
import Counter from '@/helpers/Counter'
import setInputsErrors from '@/helpers/errors/setInputsErrors'
import { axiosApi } from '@/axios'
import Vue from 'vue'
import axios from 'axios'

let cancelRequest = null

const counter = new Counter()

const getInitialState = () => {
  return {
    pending: false,
    loaded: false,
    isMuted: false,
    commodityId: 1,
    customer: null,
    type: null,
    vinPhoto: null,
    vinSaved: null,
    carTypeInputs: {
      vin: {
        value: null,
        error: null,
      },
      reuse_vin: {
        value: null,
        error: null,
      },
      make_model: {
        value: null,
        error: null,
      },
      year: {
        value: null,
        error: null,
      },
      volume: {
        value: null,
        error: null,
      },
      gross_weight: {
        value: null,
        error: null,
      },
      commodity_packages_type: {
        value: null,
        saveFullObject: true,
        error: null,
      },
      total_package: {
        value: null,
        error: null,
      },
      value: {
        value: null,
        error: null,
      },
      commer_value: {
        value: null,
        error: null,
      },
    },
    otherTypeInputs: {
      descr: {
        value: null,
        error: null,
      },
      commodity_packages_type: {
        value: null,
        saveFullObject: true,
        error: null,
      },
      total_package: {
        value: null,
        error: null,
      },
      value: {
        value: null,
        error: null,
      },
      volume: {
        value: null,
        error: null,
      },
      gross_weight: {
        value: null,
        error: null,
      },
      commer_value: {
        value: null,
        error: null,
      },
    },
    files: [],
    filesToRemove: [],
    waitingFiles: [],
    addedFiles: [],
    documents: [],
    documents_status: {
      value: null,
      error: null,
    },
    documents_location: {
      value: null,
      error: null,
    },
    documentsToRemove: [],
    additionalCommodities: [],
    notes: [],
    uploadRequests: {},
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState())
    },

    resetErrors(state) {
      ;[
        state.carTypeInputs,
        state.otherTypeInputs,
        state.documents,
        {
          0: state.documents_status,
          1: state.documents_location,
        },
      ].forEach((inputs) => {
        for (let key in inputs) {
          inputs[key].error = null
        }
      })
    },
    setDocumentsErrors(state, errors) {
      if (!errors) return

      state.documents.forEach((document, index) => {
        if (!errors[index]) return

        setInputsErrors(document.inputs, errors[index].document_inputs)
      })
    },
    setDocumentStatusLocationErrors(state, errors) {
      if (!errors) return

      let inputs = {
        documents_status: state.documents_status,
        documents_location: state.documents_location,
      }

      setInputsErrors(inputs, errors)
    },

    setMuted(state, data = true) {
      state.isMuted = data
    },
    setCustomer(state, data) {
      state.customer = data || null
    },
    setType(state, data) {
      state.type = data || null
    },
    setCarTypeInputs(state, data) {
      state.carTypeInputs = data || {}
    },
    setCarTypeInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.carTypeInputs, name))
        return
      state.carTypeInputs[name].value = value || null
    },
    setCarTypeInputErrors(state, errors) {
      setInputsErrors(state.carTypeInputs, errors)
    },
    setOtherTypeInputs(state, data) {
      state.otherTypeInputs = data || {}
    },
    setOtherTypeInputValue(state, { name, value }) {
      if (!Object.prototype.hasOwnProperty.call(state.otherTypeInputs, name))
        return
      state.otherTypeInputs[name].value = value || null
    },
    setOtherTypeInputErrors(state, errors) {
      setInputsErrors(state.otherTypeInputs, errors)
    },
    setDocuments(state, data) {
      state.customer = data || []
    },
    setDocumentInputValue(state, { documentIndex, inputName, value }) {
      if (
        !Object.prototype.hasOwnProperty.call(
          state.documents[documentIndex].inputs,
          inputName
        )
      )
        return
      state.documents[documentIndex].inputs[inputName].value = value
    },
    addDocument(state) {
      state.documents.push(createDocument())
    },
    removeDocument(state, index) {
      if (state.documents[index].id) {
        state.documentsToRemove.push(state.documents[index].id)
      }
      state.documents.splice(index, 1)
    },
    addDocumentFile(state, { documentIndex, files = [] }) {
      if (!state.documents[documentIndex]) return
      state.documents[documentIndex].files =
        state.documents[documentIndex].files.concat(files)
    },
    removeDocumentFile(state, { documentIndex, fileIndex }) {
      if (!state.documents[documentIndex]) return
      state.documents[documentIndex].files.splice(fileIndex, 1)
    },
    addAdditionalCommodity(state) {
      state.additionalCommodities.push(createAdditionalCommodity())
    },
    removeAdditionalCommodity(state, index) {
      state.additionalCommodities.splice(index, 1)
    },
    setAdditionalCommodityValue(state, { index, value }) {
      if (!state.additionalCommodities[index]) return
      state.additionalCommodities[index].value = value
    },
    addNote(state) {
      state.notes.push(createNote())
    },
    removeNote(state, index) {
      state.notes.splice(index, 1)
    },
    setNoteValue(state, { index, value }) {
      if (!state.notes[index]) return
      state.notes[index].note = value
    },
    setVinPhoto(state, photoObj) {
      state.vinPhoto = photoObj || null
    },
    setField(state, { key, value }) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      state[key].value = value
    },
    addFiles(state, files = []) {
      state.files = state.files.concat(files)
    },
    removeFile(state, index) {
      if (state.files[index].id) {
        state.filesToRemove.push(state.files[index].id)
      }
      const addedIndex = state.addedFiles.findIndex(
        (item) => item === state.files[index].id
      )
      if (addedIndex >= 0) {
        state.addedFiles.splice(addedIndex, 1)
      }

      state.files.splice(index, 1)
    },
    setAll(state, data) {
      const { type, customer, commodity_item } = data
      const newState = getInitialState()

      newState.customer = customer
      newState.type = type

      if (typeof customer === 'string' && customer.length) {
        newState.customer = JSON.parse(customer)
      }

      if (type && type.vin_field) {
        setCarTypeInputs()
      } else if (type) {
        setOtherTypeInputs()
      } else {
        setCarTypeInputs()
        setOtherTypeInputs()
      }

      function setCarTypeInputs() {
        for (let key in newState.carTypeInputs) {
          newState.carTypeInputs[key].value = commodity_item.inputs[key]
        }

        state.vinSaved = commodity_item.inputs.vin || null
      }

      function setOtherTypeInputs() {
        for (let key in newState.otherTypeInputs) {
          newState.otherTypeInputs[key].value = commodity_item.inputs[key]
        }
      }

      for (let document of commodity_item.documents) {
        let documentObj = createDocument()
        documentObj.id = document.document_inputs.id

        for (let key in documentObj.inputs) {
          documentObj.inputs[key].value = document.document_inputs[key]
        }

        newState.documents.push(documentObj)
      }

      newState.documents_status.value = commodity_item.documents_status
      newState.documents_location.value = commodity_item.documents_location

      newState.notes = commodity_item.notes

      const additionalCommodities = JSON.parse(
        commodity_item.additional_commodities
      )
      if (additionalCommodities && additionalCommodities.length) {
        const additionalCommodity = createAdditionalCommodity()
        if (additionalCommodities[0]) {
          additionalCommodity.value = additionalCommodities[0]
          newState.additionalCommodities.push(additionalCommodity)
        }
      }

      newState.files = commodity_item.files || []

      Object.assign(state, newState)
    },
    addWaitingFile(state, id) {
      state.waitingFiles.push(id)
    },
    removeWaitingFile(state, index) {
      if (cancelRequest && cancelRequest._id === state.waitingFiles[index]) {
        cancelRequest.source.cancel()
      }
      state.waitingFiles.splice(index, 1)
    },
    setFileId(state, [index, id]) {
      state.addedFiles.push(id)
      Vue.set(state.files[index], 'id', id)
    },
    setFileProgress(state, [index, progress]) {
      Vue.set(state.files[index], 'progress', progress)
    },
    addUploadRequest(state, [id, request, cancel]) {
      Vue.set(state.uploadRequests, id, { request, cancel })
    },
    removeUploadRequest(state, id) {
      Vue.delete(state.uploadRequests, id)
    },
  },
  actions: {
    async addFiles({ state, commit, dispatch }, files) {
      commit(
        'addFiles',
        files.map((item) => {
          item.progress = 0
          return item
        })
      )

      // add waiting
      for (let file of files) {
        if (!state.waitingFiles.includes(file._id)) {
          commit('addWaitingFile', file._id)

          const formData = new FormData()

          if (file.file) {
            formData.append('file', file.file)
          }

          const CancelToken = axios.CancelToken

          const cancel = {
            _id: file._id,
            source: CancelToken.source(),
          }

          const request = () => {
            if (!state.waitingFiles.includes(file._id)) {
              commit('removeUploadRequest', file._id)
              cancelRequest = null
              return Promise.resolve()
            }

            return axiosApi
              .post(`/temp_storage/`, formData, {
                cancelToken: cancel.source.token,
                onUploadProgress: (progressEvent) => {
                  const currentFileIndex = state.files.findIndex(
                    (item) => item._id === file._id
                  )
                  if (currentFileIndex >= 0) {
                    commit('setFileProgress', [
                      currentFileIndex,
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      ),
                    ])
                  }
                },
              })
              .then(({ data }) => {
                const currentFileIndex = state.files.findIndex(
                  (item) => item._id === file._id
                )
                if (currentFileIndex >= 0) {
                  commit('setFileId', [currentFileIndex, data.data.id])
                }
              })
              .finally(async () => {
                const currentFileIndex = state.files.findIndex(
                  (item) => item._id === file._id
                )
                if (
                  currentFileIndex >= 0 &&
                  !state.files[currentFileIndex].id
                ) {
                  commit('removeFile', currentFileIndex)
                }
                const currentWaitingIndex = state.waitingFiles.findIndex(
                  (item) => item === file._id
                )
                if (currentWaitingIndex >= 0) {
                  commit('removeWaitingFile', currentWaitingIndex)
                }
                commit('removeUploadRequest', file._id)
                cancelRequest = null
                await dispatch('checkUpload')
              })
            // .catch(Vue.prototype.$errorCatchDefault)
          }

          commit('addUploadRequest', [file._id, request, cancel])
        }
      }

      await dispatch('checkUpload')
    },
    async checkUpload({ state }) {
      if (cancelRequest) return
      for (let key in state.uploadRequests) {
        if (cancelRequest) {
          return
        } else {
          cancelRequest = state.uploadRequests[key].cancel
          await state.uploadRequests[key].request()
        }
      }
    },
    removeFile({ state, commit }, index) {
      const waitingIndex = state.waitingFiles.findIndex(
        (item) => item === state.files[index]._id
      )
      if (waitingIndex >= 0) {
        commit('removeUploadRequest', state.files[index]._id)
        commit('removeWaitingFile', waitingIndex)
      }
      commit('removeFile', index)
    },
    setAll({ commit }, commodity) {
      commit('setAll', {
        type: commodity.type,
        customer: commodity.customer,
        commodity_item: commodity.commodity_item,
      })

      commit('setMuted', false)
    },
    setErrors({ rootState, commit }, errors) {
      if (!errors) return

      if (rootState.commodity?.main?.type?.vin_field) {
        commit('setCarTypeInputErrors', errors.inputs)
      } else {
        commit('setOtherTypeInputErrors', errors.inputs)
      }

      commit('setDocumentsErrors', errors.document)
      commit('setDocumentStatusLocationErrors', errors.commodity_item)
    },
    cancel() {
      if (cancelRequest) {
        cancelRequest.source.cancel()
      }
    },
  },
}

function createDocument() {
  return {
    _id: counter.value,
    inputs: {
      type: {
        value: null,
        saveFullObject: true,
        error: null,
      },
      document: {
        value: null,
        error: null,
      },
      state: {
        value: null,
        saveFullObject: true,
        error: null,
      },
    },
    files: [],
  }
}

function createAdditionalCommodity() {
  return {
    _id: counter.value,
    value: null,
    error: null,
  }
}

function createNote() {
  return {
    _id: counter.value,
    note: null,
  }
}
