import VModal from './VModal.vue'

export default {
  install(Vue, options) {
    Vue.component('v-modal', VModal)
    const closeAllModalTriggers = 'a._active, .close-all-modals'
    let openTimer = null
    const { rootContainer, router, store } = options
    const RootContainer = rootContainer
    if (!RootContainer) {
      console.warn(
        `v-modal plugin aspect property 'rootContainer' in options object. Example: Vue.use(vModal, {rootContainer: vModalContainerComponent})`
      )
      return
    }

    const lastNames = new Set()

    const root = new Vue({
      name: 'VModalPlugin',
      router,
      store,
      data: {
        modals: {},
        lastModalsNames: [],
      },
      render: (createElement) => createElement(RootContainer),
    })

    root.$mount(document.body.appendChild(document.createElement('div')))
    root.$on('close', () => {
      root.$vModal.close()
    })

    function removeModalName(name) {
      lastNames.delete(name)
      root.$set(root, 'lastModalsNames', Array.from(lastNames))
      root.$delete(root.modals, name)
    }

    Vue.prototype.$vModal = {
      root,
      open(name, props, title) {
        clearTimeout(openTimer)
        root.$set(root.modals, name, {
          props,
          title,
        })
        if (lastNames.has(name)) {
          lastNames.delete(name)
        }
        lastNames.add(name)
        root.$set(root, 'lastModalsNames', Array.from(lastNames))
        openTimer = setTimeout(() => {
          const modalEl = document.querySelector(`[data-modal-name='${name}']`)
          if (modalEl) {
            modalEl.onclick = (e) => {
              if (e.target.closest(closeAllModalTriggers)) {
                root.$vModal.close()
              }
              if (e.target.closest('.close-current-modal')) {
                root.$vModal.close(name)
              }
            }
            document.documentElement.addEventListener('keyup', closeByESC)
            this.blockScroll()
          } else {
            console.warn(
              `Can't open v-modal, because v-modal with name "${name}" not found.`
            )
            removeModalName(name)
          }
        }, 33)
      },
      close(name) {
        if (name) {
          removeModalName(name)
        } else {
          for (let name of root.lastModalsNames) {
            lastNames.delete(name)
          }
          root.$set(root, 'lastModalsNames', [])
          root.$set(root, 'modals', {})
        }

        this.unBlockScroll()

        if (!root.lastModalsNames.length) {
          document.documentElement.removeEventListener('keyup', closeByESC)
        }
      },
      blockScroll() {
        const scrollWidth = getScrollWidth()
        document.documentElement.classList.add('_modal-open')
        document.documentElement.style.overflowY = 'hidden'
        document.documentElement.style.paddingRight = scrollWidth + 'px'
      },
      unBlockScroll() {
        if (root.lastModalsNames.length) return
        document.documentElement.classList.remove('_modal-open')
        document.documentElement.style.overflowY = null
        document.documentElement.style.paddingRight = null
      },
    }

    function closeByESC(e) {
      if (e.key === 'Escape') {
        if (e.target.closest('.v-lightbox')) return

        if (root.lastModalsNames.length) {
          Vue.prototype.$vModal.close(
            root.lastModalsNames[root.lastModalsNames.length - 1]
          )
        }

        if (!root.lastModalsNames.length) {
          document.documentElement.removeEventListener('keyup', closeByESC)
        }
      }
    }
  },
}

function getScrollWidth() {
  return (
    window.innerWidth - document.documentElement.clientWidth ||
    parseFloat(getComputedStyle(document.documentElement).paddingRight) ||
    0
  )
}
