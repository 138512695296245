import router from '@/router'
import store from '@/store'
import Vue from 'vue'
import BBooking from '@/components/Blanks/BBooking'
import createPdfFromHTML from '@/services/createPdfFromHTML'
import { axiosApi } from '@/axios'

function loadBooking(id) {
  return axiosApi.get(`/bookings/${id}`)
}

export default function createBlank(bookings) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const result = []

      for (let booking of bookings) {
        try {
          if (typeof booking === 'number') {
            booking = await loadBooking(booking)
            booking = booking.data.attributes
          }
        } catch (e) {
          return reject(
            `Cant load booking with id "${booking}" for creation blank`
          )
        }

        const App = Vue.extend({
          router,
          store,

          render: (createElement) =>
            createElement(
              'div',
              // {
              //   style: {
              //     overflow: 'hidden',
              //     position: 'relative',
              //   },
              // },
              [
                createElement(BBooking, {
                  props: {
                    booking: booking,
                  },
                  // style: {
                  //   position: 'absolute',
                  //   top: '200%',
                  // },
                }),
              ]
            ),
        })

        const app = new App()
        const formData = await createPdfFromHTML(
          app.$mount().$children[0],
          'for-blanks'
        )
        result.push({
          booking,
          formData,
        })

        app.$destroy()
      }

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}
