import Vue from 'vue'
import { CancelToken } from 'axios'
import { axiosApi } from '@/axios'
import {
  QUERY_PAGE_NAME,
  QUERY_LOADING_PLACE_NAME,
  QUERY_PORT_OF_DISCHARGE_NAME,
  QUERY_PLACE_OF_DELIVERY_NAME,
  QUERY_PORT_OF_LOADING_NAME,
} from '@/constants/commodity'

// import { sortBy } from 'lodash';
function getState() {
  return {
    pending: false,
    cancelRequest: null,
    rate: null,
    shippingRatesData: [],
    destinations: [],
    meta: null,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    updateShippingRatesData(state, id) {
      state.shippingRatesData = state.shippingRatesData.filter(
        (item) => item.id !== id
      )
    },
    selectShippingRatesData(state, { index, value }) {
      state.shippingRatesData = state.shippingRatesData.map((el, idx) => {
        if (index === idx) {
          el = {
            ...el,
            selected: value,
          }
          return el
        }
        return el
      })
    },
    selectToggleShippingRatesData(state, { value }) {
      state.shippingRatesData = state.shippingRatesData.map((el) => {
        return {
          ...el,
          selected: value,
        }
      })
    },
    setRate(state, payload) {
      state.rate = payload
    },
  },
  actions: {
    getRates({ state, commit }, { route }) {
      if (typeof state.cancelRequest === 'function') {
        state.cancelRequest()
        commit('setProperty', ['cancelRequest', null])
      }

      const header = {
        cancelToken: new CancelToken(function executor(c) {
          commit('setProperty', ['cancelRequest', c])
        }),
        params: {
          page: route.query[QUERY_PAGE_NAME] || 1,
          loading_place: route.query[QUERY_LOADING_PLACE_NAME],
          port_of_discharge: route.query[QUERY_PORT_OF_DISCHARGE_NAME],
          place_of_delivery: route.query[QUERY_PLACE_OF_DELIVERY_NAME],
          port_of_loading: route.query[QUERY_PORT_OF_LOADING_NAME],
          query: route.query.search,
        },
      }

      commit('setProperty', ['pending', true])
      commit('setProperty', ['meta', null])

      return axiosApi
        .get(`/shipping-rates`, header)
        .then(({ data }) => {
          commit('setProperty', [
            'shippingRatesData',
            data.data.map((el) => {
              return (el = {
                ...el.attributes,
                selected: false,
              })
            }),
          ])
          commit('setProperty', ['destinations', data.destinations])
          commit('setProperty', ['meta', data.meta])
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
    getRateById({ commit }, { id }) {
      commit('setProperty', ['pending', true])
      return axiosApi
        .get(`/shipping-rates/${id}`)
        .then((response) => {
          commit('setRate', response.data.data.attributes)
        })
        .catch((/*err*/) => {
          // console.log(err)
        })
        .finally(() => {
          commit('setProperty', ['pending'])
        })
    },
    deleteRate({ commit }, id) {
      return axiosApi
        .delete(`/shipping-rates/${id}`)
        .then(() => {
          commit('updateShippingRatesData', id)
        })
        .catch((e) => {
          throw e
        })
    },
    massDeleteRates(_, { id }) {
      return axiosApi
        .delete('/shipping-rates/mass_delete', {
          params: {
            id,
          },
        })
        .then(() => {})
        .catch((/*err*/) => {})
    },
  },
  getters: {
    selectedShippingRatesData(state) {
      return state.shippingRatesData
        .filter((el) => !!el.selected)
        .map((el) => el.id)
    },
    selectedAmount(state) {
      return state.shippingRatesData.reduce((result, item) => {
        return item.selected ? result + 1 : result
      }, 0)
    },
    isSomeSelected(state, getters) {
      return !!(state.shippingRatesData.length && getters.selectedAmount)
    },
    isAllSelected(state, getters) {
      return getters.selectedAmount === state.shippingRatesData.length
    },
    from(state) {
      if (state.meta) {
        return state.meta.from || 1
      }
      return 1
    },
    to(state) {
      if (state.meta) {
        return state.meta.to || 1
      }
      return 1
    },
    perPage(state) {
      if (state.meta) {
        return state.meta.per_page || 10
      }
      return 10
    },
  },
}
