import '@/assets/img/svg/sprite-main.svg'
import '@/assets/less/normalize/normalize.less'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import 'vue2-datepicker/index.css'
import 'vue-advanced-cropper/dist/style.css'

import Vue from 'vue'
import '@/directive'
import '@/filters'
import '@/components/_global'
import '@/assets/less/style.less'
import '@/assets/css-file-doc/file-doc-global-spec.less'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import * as Sentry from '@sentry/vue'

import { get } from 'lodash'
import { abilitiesPlugin } from '@casl/vue'
import VueScrollTo from 'vue-scrollto'

import vModal from '@/plugins/v-modal'
import vAccept from '@/plugins/v-accept'
import vSlideToggle from '@/plugins/v-slide-toggle'
import vLightBox from '@/plugins/v-lightbox'
import toaster from '@/plugins/toaster'

import errorCatchDefault from '@/services/errorCatchDefault'
import abilitySubjects from '@/services/ability/abilitySubjects'
import abilityActions from '@/services/ability/abilityActions'
import { ability } from '@/services/ability'
import vModalContainer from '@/components/TheModals'

import { isDev } from '@/axios'

//camera polyfill
if (navigator.mediaDevices === undefined) {
  navigator.mediaDevices = {}
}

if (navigator.mediaDevices.getUserMedia === undefined) {
  navigator.mediaDevices.getUserMedia = function (constraints) {
    const getUserMedia =
      navigator.webkitGetUserMedia || navigator.mozGetUserMedia

    if (!getUserMedia) {
      return Promise.reject(new Error('User media not available'))
    }

    return new Promise((resolve, reject) => {
      getUserMedia.call(navigator, constraints, resolve, reject)
    })
  }
}

Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$errorCatchDefault = errorCatchDefault(Vue)
Vue.prototype.$abilitySubjects = abilitySubjects
Vue.prototype.$ableRead = abilityActions.read
Vue.prototype.$ableManage = abilityActions.manage

Vue.use(abilitiesPlugin, ability)
Vue.use(vLightBox)
Vue.use(vAccept)
Vue.use(vSlideToggle)
Vue.use(VueScrollTo)
Vue.use(toaster)

Vue.use(vModal, {
  store,
  router,
  rootContainer: vModalContainer,
})
//--Sentry
if (process.env.NODE_ENV === 'production' && !isDev) {
  const appVariant = process.env.VUE_APP_VARIANT || 'shipping'
  Sentry.init({
    Vue,
    environment: `production_${appVariant}`,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 0,
    trackComponents: true,
    ignoreErrors: [
      'Request canceled',
      'canceled',
      'Request aborted',
      `Unexpected token '<'`,
      'Loading CSS chunk',
      'Loading chunk',
      'ChunkLoadError',
      'code 401',
      'Operation canceled',
    ],
    release: process.env.VUE_APP_SENTRY_RELEASE,
  })
  console.info(`VUE_APP_SENTRY_RELEASE: ${process.env.VUE_APP_SENTRY_RELEASE}`)
}
//--
export default new Vue({
  name: 'MainApp',
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
