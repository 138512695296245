import Vue from 'vue'
import axios from 'axios'
import { axiosApi } from '@/axios'
import router from '@/router'
import edit from './edit'
import pdf from './pdf'
import print from './print'
import email from './email'
import {
  QUERY_SEARCH_NAME,
  QUERY_PAGE_NAME,
  PER_PAGE_LIMIT,
} from '@/constants/bookings'

const CancelToken = axios.CancelToken

export default {
  namespaced: true,
  modules: {
    edit,
    pdf,
    print,
    email,
  },
  state: {
    onlyVacantBookings: true,
    items: [],
    total: 0,
    cancel: null,
    pendingList: false,

    //ONE
    oneItem: null,
    personOne: null,
    pendingOneItem: false,
    pendingFiles: false,
    cancelOneItem: null,
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
    onSelectionChange(state, [index, value]) {
      if (!state.items[index]) return
      Vue.set(state.items[index], 'selected', value)
    },
    onAllSelectionChange(state, value) {
      state.items.forEach((item) => {
        Vue.set(item, 'selected', value)
      })
    },
    pendingFiles(state, value) {
      state.pendingFiles = value
    },
    addFiles(state, files) {
      state.personOne.files = [].concat(files, state.personOne.files)
    },
    deleteFile(state, index) {
      state.personOne.files.splice(index, 1)
    },
    changeFile(state, { index, file }) {
      Vue.set(state.personOne.files, index, file)
    },
  },
  actions: {
    refresh({ dispatch }) {
      const isBookingList = router.currentRoute.matched.find(
        (route) => route.name === 'bookings'
      )
      const isOneBooking = router.currentRoute.matched.find(
        (route) => route.name === 'booking'
      )

      if (isOneBooking) {
        dispatch('loadOne', router.currentRoute.params.bookingId)
      }

      if (isBookingList) {
        dispatch('loadList')
      }
    },
    uploadFiles({ state, commit }, { files }) {
      // console.log(state.props)
      if (!state.personOne.id) return
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }

      commit('pendingFiles', true)

      axiosApi
        .post(`bookings/${state.personOne.id}/files/`, formData)
        .then(({ data }) => {
          commit(
            'addFiles',
            data.map((item) => item.attributes)
          )
        })
        .finally(() => {
          commit('pendingFiles', false)
        })
    },
    changeName({ state, commit }, { index, name }) {
      const file = state.personOne.files[index]

      const sendData = {
        name,
      }

      commit('pendingFiles', true)
      axiosApi
        .put(
          `bookings/${state.personOne.id}/files/${file.id}/change_name`,
          sendData
        )
        .then(({ data }) => {
          commit('changeFile', { index, file: data.item.attributes })
        })
        .finally(() => {
          commit('pendingFiles', false)
        })
    },
    deleteFile({ state, commit }, { index }) {
      const file = state.personOne.files[index]
      commit('pendingFiles', true)
      axiosApi
        .delete(`bookings/${state.personOne.id}/files/${file.id}`)
        .then(() => {
          commit('deleteFile', index)
        })
        .finally(() => {
          commit('pendingFiles', false)
        })
    },
    loadList({ state, commit }) {
      if (typeof state.cancel === 'function') {
        state.cancel()
      }

      commit('setProperty', ['pendingList', true])

      const params = {
        query: router.currentRoute.query[QUERY_SEARCH_NAME],
        limit: PER_PAGE_LIMIT,
        page: +router.currentRoute.query[QUERY_PAGE_NAME] || 1,
        vacant: state.onlyVacantBookings ? 1 : 0,
      }

      return axiosApi
        .get('/bookings', {
          params,
          cancelToken: new CancelToken(function executor(c) {
            commit('setProperty', ['cancel', c])
          }),
        })
        .then(({ data }) => {
          const items = data.data.map(function (item) {
            let a = item.attributes
            a.selected = false
            return a
          })

          commit('setProperty', ['items', items])
          commit('setProperty', ['total', data.meta.total])
        })
        .finally(() => {
          commit('setProperty', ['pendingList', false])
        })
    },
    multipleDelete({ dispatch }, ids) {
      return axiosApi
        .delete(`bookings/mass_delete`, {
          params: {
            id: ids,
          },
        })
        .then((data) => {
          const isOneBooking = router.currentRoute.matched.find(
            (route) => route.name === 'booking'
          )
          if (isOneBooking) {
            router
              .push({
                name: 'bookings',
                params: { ...router.currentRoute.params },
                query: { ...router.currentRoute.query },
              })
              .catch(() => {})
          }

          dispatch('refresh')

          return data
        })
    },
    loadOne({ state, commit }, id) {
      if (typeof state.cancelOneItem === 'function') {
        state.cancelOneItem()
      }

      commit('setProperty', ['pendingOneItem', true])

      return axiosApi
        .get(`/bookings/${id}`, {
          cancelToken: new CancelToken(function executor(c) {
            commit('setProperty', ['cancelOneItem', c])
          }),
        })
        .then(({ data }) => {
          commit('setProperty', ['oneItem', data.attributes])
          commit('setProperty', ['personOne', data.attributes])
          return data.attributes
        })
        .finally(() => {
          commit('setProperty', ['pendingOneItem', false])
        })
        .catch((e) => {
          return e
        })
    },
  },
}
