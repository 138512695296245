import Vue from 'vue'
import { get } from 'lodash'
import createPdfFromHTML from '@/services/createPdfFromHTML'
import router from '@/router'
import store from '@/store'

/**
 * Assign the project to an employee.
 * @param {Object} props - props.
 * @param {Array} props.models - Array of models or ids.
 * @param {Object, Number} props.models[model] - Model Object or ID for loading model.
 * @param {Function} props.loadModelsMethod - Method fro loading if models are ids array.
 * @param {Object} props.blankComponent - Component for rendering model.
 * @param {String} props.blankComponentPropKey - Key for blank component prop.
 */

export default function createBlank(props) {
  const { models, loadModelsMethod, blankComponent, blankComponentPropKey } =
    props

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const result = []

      for (let model of models) {
        try {
          if (typeof model === 'number' || typeof model === 'string') {
            model = await loadModelsMethod(model)
            model =
              get(model, 'data.attributes') ||
              get(model, 'data.data.attributes')
          }
        } catch (e) {
          return reject(
            `Cant load ${blankComponentPropKey} id "${model}" for creation blank`
          )
        }

        const App = Vue.extend({
          router,
          store,
          render: (createElement) =>
            createElement(
              'div',
              // {
              //   style: {
              //     overflow: 'hidden',
              //     position: 'relative',
              //   },
              // },
              [
                createElement(blankComponent, {
                  props: {
                    [blankComponentPropKey]: model,
                  },
                  // style: {
                  //   position: 'absolute',
                  //   top: '200%',
                  // },
                }),
              ]
            ),
        })

        const app = new App()
        const $child = app.$mount().$children[0]
        const formData = await createPdfFromHTML($child)
        result.push({
          model,
          formData,
        })

        app.$destroy()
      }

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}
