export default {
  CALENDAR: 'CALENDAR',
  CUSTOMERS: 'CUSTOMERS',
  CUSTOMERS_OVERVIEW: 'CUSTOMERS_OVERVIEW',
  CUSTOMERS_NOTE: 'CUSTOMERS_NOTE',
  CUSTOMERS_COMMODITIES: 'CUSTOMERS_COMMODITIES',
  CUSTOMERS_SHIPPING_FILES: 'CUSTOMERS_SHIPPING_FILES',
  CUSTOMERS_SALES: 'CUSTOMERS_SALES',
  CUSTOMERS_SALES_INVOICES: 'CUSTOMERS_SALES_INVOICES',
  CUSTOMERS_SALES_ESTIMATES: 'CUSTOMERS_SALES_ESTIMATES',
  CUSTOMERS_SALES_PAYMENTS: 'CUSTOMERS_SALES_PAYMENTS',
  CUSTOMERS_SALES_DISCOUNTS: 'CUSTOMERS_SALES_DISCOUNTS',
  CUSTOMERS_SALES_EXPENSES: 'CUSTOMERS_SALES_EXPENSES',
  CUSTOMERS_EMAILS: 'CUSTOMERS_EMAILS',
  CUSTOMERS_MESSAGES: 'CUSTOMERS_MESSAGES',
  CUSTOMERS_PONE_CALLS: 'CUSTOMERS_PONE_CALLS',
  CUSTOMERS_SETTINGS: 'CUSTOMERS_SETTINGS',
  CUSTOMERS_LOGS: 'CUSTOMERS_LOGS',
  CUSTOMERS_REPORTS: 'CUSTOMERS_REPORTS',
  VENDORS: 'VENDORS',
  VENDORS_OVERVIEW: 'VENDORS_OVERVIEW',
  VENDORS_NOTES: 'VENDORS_NOTES',
  VENDORS_EXPENSES: 'VENDORS_EXPENSES',
  VENDORS_SUBCONTRACTS: 'VENDORS_SUBCONTRACTS',
  VENDORS_EMAILS: 'VENDORS_EMAILS',
  VENDORS_MESSAGES: 'VENDORS_MESSAGES',
  VENDORS_PHONE_CALLS: 'VENDORS_PHONE_CALLS',
  VENDORS_LOGS: 'VENDORS_LOGS',
  SALES: 'SALES',
  SALES_INVOICES: 'SALES_INVOICES',
  SALES_INVOICES_ITEM_FORM_REGULAR: 'SALES_INVOICES_ITEM_FORM_REGULAR',
  SALES_INVOICES_ITEM_FORM_TAX: 'SALES_INVOICES_ITEM_FORM_TAX',
  SALES_ESTIMATES: 'SALES_ESTIMATES',
  SALES_ESTIMATES_ITEM_FORM_REGULAR: 'SALES_ESTIMATES_ITEM_FORM_REGULAR',
  SALES_ESTIMATES_ITEM_FORM_TAX: 'SALES_ESTIMATES_ITEM_FORM_TAX',
  SALES_INVOICES_GENERATOR: 'SALES_INVOICES_GENERATOR',
  SALES_PAYMENT_RECEIVED: 'SALES_PAYMENT_RECEIVED',
  SALES_DEPOSITS: 'SALES_DEPOSITS',
  RATES: 'RATES',
  RATES_DELIVERY: 'RATES_DELIVERY',
  RATES_STORAGE: 'RATES_STORAGE',
  RATES_SHIPPING: 'RATES_SHIPPING',
  EXPENSES: 'EXPENSES',
  COMMODITIES: 'COMMODITIES',
  BOOKINGS: 'BOOKINGS',
  SHIPPING_FILES: 'SHIPPING_FILES',
  SHIPPING_FILES_CONTAINER_CUSTOMER_DOCUMENTS:
    'SHIPPING_FILES_CONTAINER_CUSTOMER_DOCUMENTS',
  SHIPPING_FILES_CONTAINER_CUSTOMER_AES_ITN:
    'SHIPPING_FILES_CONTAINER_CUSTOMER_AES_ITN',
  SHIPPING_FILES_CONTAINER_CUSTOMER_INVOICES:
    'SHIPPING_FILES_CONTAINER_CUSTOMER_INVOICES',
  SHIPPING_FILES_CONTAINER_CUSTOMER_ESTIMATES:
    'SHIPPING_FILES_CONTAINER_CUSTOMER_ESTIMATES',
  SHIPPING_FILES_FILE_LOADING: 'SHIPPING_FILES_FILE_LOADING',
  SHIPPING_FILES_FILE_LOADING_CHAT: 'SHIPPING_FILES_FILE_LOADING_CHAT',
  SHIPPING_FILES_FILE_DOCUMENTS: 'SHIPPING_FILES_FILE_DOCUMENTS',
  SHIPPING_FILES_FILE_PICTURES: 'SHIPPING_FILES_FILE_PICTURES',
  SHIPPING_FILES_FILE_EXPENSES: 'SHIPPING_FILES_FILE_EXPENSES',
  SHIPPING_FILES_FILE_INVOICES: 'SHIPPING_FILES_FILE_INVOICES',
  SHIPPING_FILES_FILE_INVENTORY: 'SHIPPING_FILES_FILE_INVENTORY',
  SHIPPING_FILES_FILE_TIME_LOG: 'SHIPPING_FILES_FILE_TIME_LOG',
  SHIPPING_FILES_FILE_FILE_REPORT: 'SHIPPING_FILES_FILE_FILE_REPORT',
  SHIPPING_FILES_FILE_LOGS: 'SHIPPING_FILES_FILE_LOGS',
  AES_ITN_MANAGER: 'AES_ITN_MANAGER',
  INVENTORY: 'INVENTORY',
  TIME_LOG: 'TIME_LOG',
  REPORTS: 'REPORTS',
  TIME_KIOSK: 'TIME_KIOSK',
  MAILING: 'MAILING',
  HELP: 'HELP',
  SETTINGS: 'SETTINGS',
  ACCOUNT: 'ACCOUNT',
  ACCOUNT_PROFILE: 'ACCOUNT_PROFILE',
  ACCOUNT_COMPANY: 'ACCOUNT_COMPANY',
  MAIN_MENU: 'MAIN_MENU',
  PROJECTS: 'PROJECTS',
  BANKING: 'BANKING',
}
