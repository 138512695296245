import router from '@/router'
import Vue from 'vue'
import BShippingFile from '@/components/Blanks/BShippingFile'
import createPdfFromHTML from '@/services/createPdfFromHTML'
import { axiosApi } from '@/axios'
import store from '@/store'
function loadShippingFile(id) {
  return axiosApi.get(`/shipping_files/${id}`)
}

export default function createBlank(items) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const result = []

      for (let item of items) {
        try {
          if (typeof item === 'number') {
            item = await loadShippingFile(item)
            item = item.data.attributes
          }
        } catch (e) {
          return reject(
            `Cant load shipping file with id "${item}" for blank creation`
          )
        }

        const App = Vue.extend({
          router,
          store,
          render: (createElement) =>
            createElement(
              'div',
              // {
              //   style: {
              //     overflow: 'hidden',
              //     position: 'relative',
              //   },
              // },
              [
                createElement(BShippingFile, {
                  props: {
                    shippingFile: item,
                  },
                  // style: {
                  //   position: 'absolute',
                  //   top: '200%',
                  // },
                }),
              ]
            ),
        })

        const app = new App()
        const formData = await createPdfFromHTML(
          app.$mount().$children[0],
          'for-blanks'
        )
        result.push({
          shippingFile: item,
          formData,
        })

        app.$destroy()
      }

      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}
