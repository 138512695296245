import Vue from 'vue'
import { CancelToken } from 'axios'
import { axiosApi } from '@/axios'

function getState() {
  return {
    pending: false,
    cancelRequest: null,
    rate: null,
    storageRatesData: null,
    meta: null,
  }
}

export default {
  namespaced: true,
  state: getState(),
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    selectStorageRatesData(state, { index, value }) {
      state.storageRatesData = state.storageRatesData.map((el, idx) => {
        if (index === idx) {
          el = {
            ...el,
            selected: value,
          }
          return el
        }
        return el
      })
    },
    selectToggleStorageRatesData(state, { value }) {
      state.storageRatesData = state.storageRatesData.map((el) => {
        return {
          ...el,
          selected: value,
        }
      })
    },
    updateStorageRatesData(state, id) {
      state.storageRatesData = state.storageRatesData.filter(
        (item) => item.id !== id
      )
    },
    setRate(state, payload) {
      state.rate = payload
    },
  },
  actions: {
    getRates({ state, commit }) {
      if (typeof state.cancelRequest === 'function') {
        state.cancelRequest()
        commit('setProperty', ['cancelRequest', null])
      }

      const header = {
        cancelToken: new CancelToken(function executor(c) {
          commit('setProperty', ['cancelRequest', c])
        }),
        params: {
          //   page: route.query[QUERY_PAGE_NAME] || 1,
          //   loading_place: route.query[QUERY_LOADING_PLACE_NAME],
          //   port_of_discharge: route.query[QUERY_PORT_OF_DISCHARGE_NAME],
          //   place_of_delivery: route.query[QUERY_PLACE_OF_DELIVERY_NAME]
        },
      }

      commit('setProperty', ['pending', true])
      commit('setProperty', ['meta', null])

      return axiosApi
        .get(`/storage-rates`, header)
        .then(({ data }) => {
          commit('setProperty', [
            'storageRatesData',
            data.data.map((el) => {
              return (el = {
                ...el.attributes,
                selected: false,
              })
            }),
          ])
          commit('setProperty', ['meta', data.meta])
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
    getRateById({ commit }, { id }) {
      commit('setProperty', ['pending', true])
      return axiosApi
        .get(`/storage-rates/${id}`)
        .then((response) => {
          commit('setRate', response.data.data.attributes)
        })
        .catch((/*err*/) => {
          // console.log(err)
        })
        .finally(() => {
          commit('setProperty', ['pending'])
        })
    },
    deleteRate({ commit }, id) {
      return axiosApi
        .delete(`/storage-rates/${id}`)
        .then(() => {
          commit('updateStorageRatesData', id)
        })
        .catch((e) => {
          throw e
        })
    },
    massDeleteRates(_, { id }) {
      return axiosApi
        .delete('/storage-rates/mass_delete', {
          params: {
            id,
          },
        })
        .then(() => {})
        .catch((/*err*/) => {})
    },
  },
  getters: {
    selectedStorageRatesData(state) {
      return state.storageRatesData
        .filter((el) => !!el.selected)
        .map((el) => el.id)
    },
    selectedAmount(state) {
      if (state.storageRatesData) {
        return state.storageRatesData.reduce((result, item) => {
          return item.selected ? result + 1 : result
        }, 0)
      }
      return null
    },
    isSomeSelected(state, getters) {
      if (state.storageRatesData) {
        return !!(state.storageRatesData.length && getters.selectedAmount)
      }
      return null
    },
    isAllSelected(state, getters) {
      if (state.storageRatesData) {
        return getters.selectedAmount === state.storageRatesData.length
      }
      return null
    },
    from(state) {
      if (state.meta) {
        return state.meta.from || 1
      }
      return 1
    },
    to(state) {
      if (state.meta) {
        return state.meta.to || 1
      }
      return 1
    },
    perPage(state) {
      if (state.meta) {
        return state.meta.per_page || 10
      }
      return 10
    },
  },
}
