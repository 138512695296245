import { get } from 'lodash'
import errorHandleSimple from '@/helpers/errors/errorHandleSimple'
import axios from 'axios'

export default function (vue) {
  return (e) => {
    if (!axios.isCancel(e)) {
      const mainError = errorHandleSimple(e)

      const errors = get(e, 'response.data.errors')

      let message = `
      <p>${mainError}</p>
      `
      if (errors) {
        message += `</br>`

        for (const key in errors) {
          const error = errors[key]

          if (error) {
            message += `
          <p>
            ${error}
          </p>
          `
          }
        }
      }

      vue.prototype.$vModal.open('warning', {
        title: 'Error!',
        message,
      })

      throw e
    }
  }
}
