import roundTo from '@/helpers/roundTo'
import Vue from 'vue'
import { axiosApi } from '@/axios'
import getAmountToPay from '@/helpers/paymentsReceived/getAmountToPay'

function getInitState() {
  return {
    pendingLoad: false,
    invoices: null,
    invoicesFromPaymentReceived: null,
    invoicesInputsErrors: null,
  }
}

export default {
  namespaced: true,
  state: getInitState(),
  getters: {
    getInvoicesToSave(state) {
      return (state.invoices || []).filter(
        (invoice) => Number(invoice.payment_input.value) > 0
      )
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getInitState())
    },
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return

      Vue.set(state, key, value)
    },
    invoicePaymentAmountInput(state, [id, value]) {
      const invoice = state.invoices.find((i) => i.id === id)
      Vue.set(invoice.payment_input, 'value', value)
    },
    invoicePayFullAmount(state, id) {
      const invoice = state.invoices.find((i) => i.id === id)

      Vue.set(invoice.payment_input, 'value', getAmountToPay(invoice))
    },
    clearInvoicesPayInputs(state) {
      state.invoices.forEach((item) => {
        Vue.set(item.payment_input, 'value', null)
      })
    },
    parseInvoicesFromPaymentReceived(state, paymentReceived) {
      state.invoicesFromPaymentReceived = paymentReceived.items.map((item) => {
        const invoice = JSON.parse(JSON.stringify(item.invoice.attributes))

        invoice.payment_input = {
          value: roundTo(Number(item.inputs.amount), 2),
        }

        invoice.inputs.balance = roundTo(
          Number(invoice.inputs.balance) + Number(item.inputs.amount),
          2
        )
        invoice.item_id = item.id

        return invoice
      })
    },
    parseInvoicesErrors(state, errors) {
      const invoicesToSave =
        this.getters[
          'paymentReceivedEdit/paymentReceivedEditInvoices/getInvoicesToSave'
        ]

      errors.forEach((e, i) => {
        const invoiceId = invoicesToSave[i].id

        const invoice = state.invoices.find((i) => i.id === invoiceId)
        Vue.set(invoice, 'error', e)
      })
    },
  },
  actions: {
    loadInvoices({ state, commit }, { customerId, byInvoiceId }) {
      commit('setProperty', ['pendingLoad', true])

      return (
        axiosApi
          .get('/invoices', {
            params: {
              limit: 1000,
              page: 1,
              customer: customerId,
              status_unpaid: '',
            },
          })
          .then(({ data }) => {
            let invoices = data.data.map((invoice) => {
              invoice = invoice.attributes
              invoice.payment_input = {
                value: null,
              }
              return invoice
            })

            // MERGE INVOICES
            const invoicesFromPaymentReceived =
              state.invoicesFromPaymentReceived
            if (
              invoicesFromPaymentReceived &&
              invoicesFromPaymentReceived.length
            ) {
              const ids = invoicesFromPaymentReceived.map((i) => i.id)

              invoices = invoices.filter((i) => !ids.includes(i.id))

              invoices = [
                ...JSON.parse(JSON.stringify(invoicesFromPaymentReceived)),
                ...invoices,
              ]
            }

            if (byInvoiceId) {
              const invoiceItemById = invoices.find(
                (item) => String(item.id) === String(byInvoiceId)
              )
              if (!invoiceItemById) return
              invoiceItemById.payment_input.value =
                invoiceItemById.inputs.balance

              commit(
                'paymentReceivedEdit/setAmount',
                invoiceItemById.inputs.balance,
                { root: true }
              )
            }

            commit('setProperty', ['invoices', invoices])
            return invoices
          })
          // .catch(Vue.prototype.$errorCatchDefault)
          .finally(() => {
            commit('setProperty', ['pendingLoad', false])
          })
      )
    },
  },
}
